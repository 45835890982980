import React from 'react'

const NetworkSecurity = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <div className="common_content mt-5">
                                    <h1>What is Network & Security Solutions?</h1>
                                    <p>Cloud computing, social media, mobile computing and hyper-connectivity have uncovered numerous new digital business opportunities and enhanced enterprise efficiency. However,this means that businesses expose larger attack surface for cyber-attacks and are now, more vulnerable. They must safeguard their assets from prevalent as well as unforeseen risks.</p>
                                    <h2>How We Help</h2>
                                    <p>SPM has marked a distinct place for offering an array of high-quality Network Security Solutions. With the support of our technical facilities, we are engaged in offering Security Solutions. For providing these services, our professionals who possess in-depth knowledge and vast experience in their concerned field use advanced technology and innovative in-house solutions.</p>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <img className="network" src="/images/network.jpg" alt="network" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NetworkSecurity
