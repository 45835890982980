import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from "react-router-dom";

const Category = () => {
    const categoryId = useParams().id;
    return (
        <section className="web_content">

            <div className="content_section_scroll">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="common_content">
                                <h1>Aviation Part</h1>
                                <div className="product_content">
                                    <div className="product_table">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADP Cent Processing Unit (CPU, Computer), Digital</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Airfield Specialized Trucks and Trails</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADP Components</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Airframe Structural Components</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADP Input/Output and Storage Devices</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Amplifiers</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADP Supplies</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Arrest, Barrier and Barricade Equipment</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADP Supplies and Equipment</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Bombing Fire Control Components</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>ADPE Systems Configuration</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Ground Servicing Equipment</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Air Conditioning Equipment</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Gunnery Fire Control Components</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Air Purification Equipment</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Landing Gear Components</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Alarm and Signal Systems</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Launching Equipment</NavLink></td>
                                                </tr>
                                                <tr>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Propellers</NavLink></td>
                                                    <td><NavLink className="nav-link" to={"/product/" + categoryId}>Aircraft Wheel and Brake Systems</NavLink></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

    )
}

export default Category
