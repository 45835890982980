import React, { useState } from "react";
//import ContactForm from "./ContactForm";
import $ from "jquery";
import { message } from "antd";
message.config({
  top: 200
});
const axios = require("axios");
const Cart = () => {
  const [wishList, setWishList] = useState(localStorage.CartData !== undefined && localStorage.CartData !== "null" ? JSON.parse(localStorage.CartData) : []);
  if (localStorage.loginData) {
    var loginData = JSON.parse(localStorage.loginData);
  } else {
    loginData = {
      fname: "",
      email: "",
      phone: "",
    };
  }

  const [FullName, setFullName] = useState(loginData.fname + " " + loginData.lname);
  const [Email, setEmail] = useState(loginData.email);
  const [PhoneNumber, setPhoneNumber] = useState(loginData.phone);
  const [Subject, setSubject] = useState("");
  const [Details, setDetails] = useState("");
  //const [partNoArray, setPartNoArray] = useState([]);
  var QuntityArray = [];
  const contactFormSubmit = (e) => {
    e.preventDefault();
    var validation = true;
    $("#nameError").text("");
    $("#emailError").text("");
    $("#phoneError").text("");
    $("#subjectError").text("");
    $("#detailsError").text("");
    $("#QuntityError").text("");

    if (FullName === "" || FullName.match("^[a-zA-Z ]*$") == null) {
      $("#nameError").text("FullName is not valid");
      validation = false;
    }
    const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!validEmail.test(Email)) {
      $("#emailError").text("Email is not valid");
      validation = false;
    }
    const validPhone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!validPhone.test(PhoneNumber)) {
      $("#phoneError").text("Phone no is not valid");
      validation = false;
    }
    if (Subject === "" || Subject.match("^[a-zA-Z ]*$") == null) {
      $("#subjectError").text("Subject is not valid");
      validation = false;
    }
    if (Details === "") {
      $("#detailsError").text("Details is not valid");
      validation = false;
    }
    var quentityVal = true;
    if (wishList) {
      QuntityArray = $("input[name='quntity[]']")
        .map(function async() {
          return $(this).val() != 0 ? $(this).val() : (quentityVal = false);
        })
        .get();
    }

    if (!quentityVal) {
      $("#QuntityError").text("Please fill quntity");
      validation = false;
    }
    console.log(QuntityArray);
    console.log(wishList);
    var requestQuote = [];
    QuntityArray.forEach((element, index) => {
      requestQuote.push(wishList[index] + " -> " + element);
    });

    if (validation === false) {
      return false;
    }

    const data = {
      FullName: FullName,
      Email: Email,
      PhoneNumber: PhoneNumber,
      Subject: Subject,
      Details: Details,
      requestQuote: JSON.stringify(requestQuote),
    };

    try {
      $(".loaderClass").show();
      axios
        .post("https://api.spmsale.com/api/send-email", data)
        .then(function (response) {
          if (response.data.Success) {
            //$(".loaderClass").hide();
            localStorage.removeItem("requestQuote");

            const data = {
              email: JSON.parse(localStorage.loginData).email,
              cart_data: [],
            };
            try {
              $(".loaderClass").show();
              axios
                .post("https://api.spmsale.com/api/update-cart", data)
                .then(function (response) {
                  localStorage.removeItem("CartData");
                })
                .catch(function (error) {
                  $(".loaderClass").hide();
                  console.log(error);
                });
            } catch (error) {
              $(".loaderClass").hide();
              console.log(error);
            }
            message.success("mail successfully sent, Please check your mail box");
            setTimeout(function(){
              $(".loaderClass").hide();
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  const removeWistList = (value) => {
    console.log(value);
    setWishList(wishList.splice($.inArray(value, wishList), 1));
    //setWishList(wishListData);
    const data = {
      email: JSON.parse(localStorage.loginData).email,
      cart_data: wishList,
    };
    try {
      $(".loaderClass").show();
      axios
        .post("https://api.spmsale.com/api/update-cart", data)
        .then(function (response) {
          if (response.data) {
            message.success('Product successfully Remove from wish list.');            
          } else {
            $("#emailError").text("Something went wrong, Please try after sometime");
          }
          localStorage.setItem("CartData", JSON.stringify(wishList));
          setTimeout(function(){
            $(".loaderClass").hide();
            window.location.reload(false);
          }, 3000);
          
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  const requestQuote = (wishList) => {
    localStorage.setItem("requestQuote", wishList);
  };

  return (
    <section className="web_content">
      <div className="content_section_scroll">
        <div className="container-fluid">
          <div className="row d-flex align-items-top">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="common_content">
                <h1>WishList</h1>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <span className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    {wishList.length > 0 ? (
                      <button type="button" className="btn btn-primary requestButton" onClick={(e) => requestQuote(wishList)} data-toggle="modal" data-target="#exampleModal">
                        Request Quote for all wishList product
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="product_content">
                  <div className="product_table">
                    <table className="table table-bordered" id="productTable">
                      <thead>
                        <tr>
                          <td className="width30">PART</td>
                          <td className="width30">Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {wishList.length > 0 ? (
                          wishList.map((element) => {
                            return (
                              <tr key={element}>
                                <td>{element}</td>
                                <td>
                                  <a className="btn_table2" href="#" onClick={(e) => removeWistList(element)}>
                                    Remove from WistList
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="ct_center" colSpan={2}>
                              Wish list is empty.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Request for Quote
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="contact_form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="text" name="full_name" value={FullName} onChange={(e) => setFullName(e.target.value)} placeholder="Name" />
                    </div>
                    <div id="nameError" className="error"></div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="text" name="phone" onChange={(e) => setPhoneNumber(e.target.value)} value={PhoneNumber} placeholder="Phone Number" />
                    </div>
                    <div id="phoneError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={Email} placeholder="E-mail" />
                    </div>
                    <div id="emailError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <input type="text" name="subject" onChange={(e) => setSubject(e.target.value)} value={Subject} placeholder="Subject" />
                    </div>
                    <div id="subjectError" className="error"></div>
                  </div>
                </div>
                {wishList.length > 0
                  ? wishList.map((element) => {
                      return (
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="cf_tab">
                              <input type="text" name="partno[]" value={element} placeholder="partno" disabled />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="cf_tab">
                              <input type="number" name="quntity[]" placeholder="Quntity" />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div id="QuntityError" className="error"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <textarea placeholder="Details" onChange={(e) => setDetails(e.target.value)} value={Details}></textarea>
                    </div>
                    <div id="detailsError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <button type="submit" className="btn btn_inquiry" onClick={contactFormSubmit}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
