import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import $ from "jquery";
import { message } from "antd";
message.config({
  top: 200
});
const axios = require("axios");
const Product = () => {
  const [Product, setProduct] = useState([]);
  const [Cart, setCart] = useState(localStorage.CartData !== undefined && localStorage.CartData !== "null" ? JSON.parse(localStorage.CartData) : []);
  const [ProductId, setProductId] = useState();
  const [page, setPage] = useState(1);
  const [Limit, setLimit] = useState(15);
  const [totalPage, setTotalPage] = useState(1);
  const getProduct = (sub_id) => {
    $(".loaderClass").show();
    try {
      axios
        .post("https://api.spmsale.com/api/product", { id: sub_id, page: 1, limit: Limit })
        .then(function (response) {
          if (response.data.status) {
            if (response.data.count % Limit) {
              setTotalPage(parseInt(response.data.count / Limit) + 1);
            } else {
              setTotalPage(parseInt(response.data.count / Limit));
            }
            setProduct(response.data.data);
          }
          $(".loaderClass").hide();
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
    $(".loaderClass").show();
    try {
      axios
        .post("https://api.spmsale.com/api/product", { id: sub_id, page: value, limit: Limit })
        .then(function (response) {
          if (response.data.status) {
            setProduct(response.data.data);
            $(".loaderClass").hide();
          }
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  const addToCart = (value) => {
    let tempCart = Cart;
    tempCart.push(value);
    setCart(tempCart);
    localStorage.setItem("CartData", JSON.stringify(tempCart));
    const data = {
      email: JSON.parse(localStorage.loginData).email,
      cart_data: localStorage.CartData,
    };
    try {
      $(".loaderClass").show();
      axios
        .post("https://api.spmsale.com/api/update-cart", data)
        .then(function (response) {
          if (response.data) {
            message.success("Product successfully added in wish list.");            
          } else {
            $("#emailError").text("Something went wrong, Please try after sometime");
          }
          setTimeout(function(){
            $(".loaderClass").hide();
            window.location.reload(false);
          }, 3000);
          
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  const { sub_id } = useParams();
  useEffect(() => {
    setProductId(sub_id);
    getProduct(sub_id);
  }, [sub_id]);
  return (
    <section className="web_content">
      <div className="content_section_scroll">
        <div className="container-fluid">
          {ProductId <= 2 ? (
            <div className="row d-flex align-items-top">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="common_content">
                  {ProductId === 1 ? <h1>Electronics Components</h1> : ""}
                  {ProductId === 2 ? <h1>Aircraft Part</h1> : ""}
                  <div className="product_content">
                    <div className="product_table">
                      <table className="table table-bordered" id="productTable">
                        <thead>
                          <tr>
                            <td className="width10">PART</td>
                            <td className="width20">MANUFACTURER NAME &amp; PART</td>
                            <td className="width30">DESCRIPTION</td>
                            <td className="width10">QUANTITY</td>
                            <td className="width30"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {Product.length > 0 ? (
                            Product.map((element) => {
                              return (
                                <tr key={element.id}>
                                  <td>
                                    <NavLink className="nav-link" to={"/products-details/" + element.part_number}>
                                      {element.part_number}
                                    </NavLink>
                                  </td>
                                  <td>{element.manufacturer}</td>
                                  <td>{element.parts_description}</td>
                                  <td>{element.total_available !== null ? element.total_available : 0}</td>
                                  <td>
                                    {/* <NavLink className="btn_table" to={"/products-details/" + element.part_number}>
                                      Request Quote
                                    </NavLink>
                                    {!localStorage.loginData ? (
                                      ""
                                    ) : $.inArray(element.part_number, Cart) === -1 ? (
                                      <a className="btn_table2" href="#" onClick={(e) => addToCart(element.part_number)}>
                                        Add to WishList
                                      </a>
                                    ) : (
                                      ""
                                    )} */}
                                    {element.total_available != null || element.total_available > 0 ? (
                                      <NavLink className="btn_table" to={"/products-details/" + element.part_number}>
                                        Request Quote
                                      </NavLink>
                                    ) : $.inArray(element.part_number, Cart) === -1 ? (
                                      <a className="btn_table2" href="#" onClick={(e) => addToCart(element.part_number)}>
                                        Add to WishList
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="ct_center" colSpan={4}>
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                {Product.length > 0 && totalPage > 1 ? <Pagination count={totalPage} page={page} onChange={handleChange} color="primary" size="large" /> : ""}
              </div>
            </div>
          ) : (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <img className="comingSoon" src="/images/coming-soon.jpg" alt="coming-soon" />
            </div>
          )}
        </div>
      </div>

      {/* <Pagination count={10} /> */}
    </section>
  );
};

export default Product;
