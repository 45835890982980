import React from 'react'

const SoftwareSolution = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <div className="common_content mt-5">
                                    <h1>What is Software Solution?</h1>
                                    <p>With the rapid development of technologies, the usability of a website is getting redefined. Today, a website is not just a source of information, but it has become a medium for social interaction, e-learning, online shopping so on and so forth. Web designers have begun to use several web-based applications into the websites to achieve the prevalent technological trend in the online business world.</p>
                                    <h2>How We Help</h2>
                                    <p>SPM provides end-to-end product development services such as application and website development as per the business requirements. Our technical expertise in developing product along with whole spectrum of tools, technology, environments and methodologies partnering with you, to give you a competitive edge in the global market place.</p>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <img className="softSolution" src="/images/soft.jpg" alt="softSolution" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SoftwareSolution
