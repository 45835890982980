import React, { useState } from "react";
import $ from "jquery";
import { message } from "antd";
message.config({
  top: 200
});
const axios = require("axios");

const ContactForm = () => {
  if (localStorage.loginData) {
    var loginData = JSON.parse(localStorage.loginData);
  } else {
    loginData = {
      fname: "",
      email: "",
      phone: "",
    };
  }

  const [FullName, setFullName] = useState(loginData.fname + " " + loginData.lname);
  const [Email, setEmail] = useState(loginData.email);
  const [PhoneNumber, setPhoneNumber] = useState(loginData.phone);
  const [Subject, setSubject] = useState("");
  const [Details, setDetails] = useState("");

  const contactFormSubmit = (e) => {
    e.preventDefault();
    var validation = true;
    $("#nameError").text("");
    $("#emailError").text("");
    $("#phoneError").text("");
    $("#subjectError").text("");
    $("#detailsError").text("");
    $("#QuntityError").text("");

    if (FullName === "" || FullName.match("^[a-zA-Z ]*$") == null) {
      $("#nameError").text("FullName is not valid");
      validation = false;
    }
    const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!validEmail.test(Email)) {
      $("#emailError").text("Email is not valid");
      validation = false;
    }
    const validPhone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!validPhone.test(PhoneNumber)) {
      $("#phoneError").text("Phone no is not valid");
      validation = false;
    }
    if (Subject === "" || Subject.match("^[a-zA-Z ]*$") == null) {
      $("#subjectError").text("Subject is not valid");
      validation = false;
    }
    if (Details === "") {
      $("#detailsError").text("Details is not valid");
      validation = false;
    }

    if (validation === false) {
      return false;
    }

    const data = {
      FullName: FullName,
      Email: Email,
      PhoneNumber: PhoneNumber,
      Subject: Subject,
      Details: Details,
      requestQuote: "From Contact form",
    };

    try {
      $(".loaderClass").show();
      axios
        .post("https://api.spmsale.com/api/send-email", data)
        .then(function (response) {
          if (response.data.Success) {
            $(".loaderClass").hide();
            message.success("mail successfully sent, Please check your mail box");
            setTimeout(function(){
              $(".loaderClass").hide();
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };

  return (
    <div className="contact_form">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="cf_tab">
            <input type="text" name="full_name" value={FullName} onChange={(e) => setFullName(e.target.value)} placeholder="Name" />
          </div>
          <div id="nameError" className="error"></div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="cf_tab">
            <input type="text" name="phone" onChange={(e) => setPhoneNumber(e.target.value)} value={PhoneNumber} placeholder="Phone Number" />
          </div>
          <div id="phoneError" className="error"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="cf_tab">
            <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={Email} placeholder="E-mail" />
          </div>
          <div id="emailError" className="error"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="cf_tab">
            <input type="text" name="subject" onChange={(e) => setSubject(e.target.value)} value={Subject} placeholder="Subject" />
          </div>
          <div id="subjectError" className="error"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="cf_tab">
            <textarea placeholder="Details" onChange={(e) => setDetails(e.target.value)} value={Details}></textarea>
          </div>
          <div id="detailsError" className="error"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="cf_tab">
            <button type="submit" className="btn btn_inquiry" onClick={contactFormSubmit}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
