import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
const axios = require("axios");

const Header = () => {
  const [loginData, setLoginData] = useState(localStorage.loginData);
  const [category, setcategory] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("loginData");
    localStorage.removeItem("CartData");
    setLoginData("");
    navigate("/login");
  };
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value) {
      if (e.target.value.length > 2) {
        navigate("/products-search/" + e.target.value);
        //window.location.reload(false);
      }
    }
  };
  const getCategory = () => {
    try {
      axios
        .post("https://api.spmsale.com/api/category")
        .then(function (response) {
          if (response.data.status) {
            setcategory(response.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const searchClick = () => {
    if ($(".search-toggle").hasClass("closed")) {
      $(".search-toggle").removeClass("closed").addClass("opened");
      $(".search-toggle, .search-container").addClass("opened");
      $("#search-terms").focus();
    } else {
      $(".search-toggle").removeClass("opened").addClass("closed");
      $(".search-toggle, .search-container").removeClass("opened");
    }
  };
  const searchSubmit = () => {
    if (searchInput) {
      navigate("/products-search/" + searchInput);
    }
    $(".search-toggle").removeClass("opened").addClass("closed");
    $(".search-toggle, .search-container").removeClass("opened");
  };
  useEffect(() => {
    getCategory();
    setLoginData(localStorage.loginData);
  }, []);
  return (
    <div>
      <div className="loaderClass">
        <div className="loaderMain">
          <div className="loader"></div>
        </div>
      </div>
      <header className="web_header" id="home">
        <div className="menu-area">
          <nav className="navbar navbar-light navbar-expand-lg mainmenu">
            <div className="container-fluid">
              <NavLink className="nav-link navbar-brand" to="/">
                <img className="img_light" src="/images/logo.png" alt="SPM Logo" />
              </NavLink>
              <div className="top_btn_mobile">
                <div className="info_button">
                  <a href="#">
                    <i className="fa fa-search"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-shopping-cart"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-user"></i>
                  </a>
                </div>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>
              </div>
              <div className="navbar-collapse collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item"><a className="nav-link active" href="index.html">Home</a></li> */}
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/">
                      Home
                      <span className="sr-only">(current)</span>
                    </NavLink>
                  </li>
                  <li className="dropdown">
                    <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Our products
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {category.map((element) => {
                        if (element.id === 5) {
                          return (
                            <li className="dropdown">
                              <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                It Solutions
                              </a>
                              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                  <NavLink className="nav-link" to={"/cloud-virtualization"}>
                                    Cloud & Virtualization
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink className="nav-link" to={"/network-security"}>
                                    Network & Security
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink className="nav-link" to={"/remote-infrastructure-management"}>
                                    Remote Infrastructure Management
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink className="nav-link" to={"/backup-solution"}>
                                    Backup Solution
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink className="nav-link" to={"/cloud-based-sales-management-tools"}>
                                    Cloud Based Sales Management Tools
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink className="nav-link" to={"/software-solution"}>
                                    Software Solution
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                          );
                        } else {
                          return (
                            <li key={element.id}>
                              <NavLink className="nav-link" to={"/product/" + element.id}>
                                {element.name}
                              </NavLink>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about-us">
                      About Us
                    </NavLink>
                  </li>
                  {loginData ? ( // if has image
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact-us">
                        Contact us
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="top_btn_desktop">
                <div className="info_search">
                  <div className="search-toggle closed">
                    <button className="search-icon icon-search" onClick={searchClick}>
                      <i className="fa fa-fw fa-search"></i>
                    </button>
                    <button className="search-icon icon-close" onClick={searchClick}>
                      <i className="fa fa-fw  fa-close"></i>
                    </button>
                  </div>
                  <div className="search-container">
                    <input type="text" onChange={handleChange} placeholder="Search terms..." />
                    <button type="submit" onClick={searchSubmit} className="search-button">
                      <i className="fa fa-fw fa-search"></i>
                    </button>
                  </div>
                </div>
                <div className="info_button">
                  {/* <NavLink className="info_anchor nav-link" to="/products-search/G6J-2FL-Y-TR-DC5"><i className="fa fa-search"></i></NavLink> */}
                  <NavLink className="nav-link info_anchor" to="/wish-list">
                  <i className="fa fa-shopping-cart"></i>
                    <span className="counter">{localStorage.CartData !== undefined && localStorage.CartData !== 'null' ? JSON.parse(localStorage.CartData).length : 0}</span>
                          </NavLink>
                  
                  <a className="info_anchor" href="#" id="userProfile">
                    <i className="fa fa-user"></i>
                  </a>
                  {
                    loginData ? ( // if has image
                      <ul className="dropdown-menu" id="userProfile_sub">
                        <li onClick={logout}>
                          <a className="nav-link" href="#">
                            Logout
                          </a>
                        </li>
                        <li>
                          <NavLink className="nav-link" to="/user-profile">
                            User Profile
                          </NavLink>
                        </li>
                      </ul> // return My image tag
                    ) : (
                      <ul className="dropdown-menu" id="userProfile_sub">
                        <li>
                          <NavLink className="nav-link" to="/login">
                            Login
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="nav-link" to="/registration">
                            Registration
                          </NavLink>
                        </li>
                      </ul>
                    ) // otherwish return other element
                  }
                </div>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
