import React from 'react'
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
const BodyContent = () => {

    return (

        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <div className="homepage_title">
                                    <h2>SPM Sales and Solution</h2>
                                    <p>SPM is a distributor of electronics components, Avionic parts and raw materials for avionics parts.</p>
                                    {/* <p>Committed to Customer Satisfaction</p> */}
                                    {/* <a href="certificate.pdf" target="_blank">SPM Sales and Solution is ISO 9001 and AS 9120B  Certified.</a> */}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12"></div>
                        </div>
                        <div className="nav_menu">
                            <div className="nav_menu_li nav_one">
                                <NavLink className="nav-link" to="/product/1"><p>01</p>
                                    <h4>Electronics <br /> Components</h4></NavLink>
                            </div>
                            <div className="nav_menu_li nav_two">
                                <NavLink className="nav-link" to="/product/2"><p>02</p>
                                    <h4>Aircraft Parts</h4></NavLink>

                            </div>
                            <div className="nav_menu_li nav_three">
                                <NavLink className="nav-link" to="/product/3"><p>03</p>
                                    <h4>Raw Materials</h4></NavLink>
                            </div>
                            <div className="nav_menu_li nav_four">
                                <NavLink className="nav-link" to="/product/4"><p>04</p>
                                    <h4>Computer <br /> Peripherals</h4></NavLink>
                            </div>
                            <div className="nav_menu_li nav_five">
                                <NavLink className="nav-link" to="/it-solutions"><p>05</p>
                                    <h4>It Solutions</h4></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </section>

    )
}

export default BodyContent
