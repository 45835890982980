
import './App.css';
import React from 'react'
import {Header, FixRight, BodyContent, Login, Registration, UserProfile, Cart, Product, AboutUs, ContactUs, Category, ProductsDetails, ProductSearch, CBSMT, RIM, CloudVirtualization, NetworkSecurity, BackupSolution, SoftwareSolution, ItSolutions } from "./components";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
function App() {
    //const [loginData, setLoginData] = useState(localStorage.loginData);
    return (
        <>
            <Router>
                <Header/>
                <FixRight/>
                <Routes>
                    <Route path="/" element={ <BodyContent />} />
                    <Route path="/login" element={ <Login />} />
                    <Route path="/registration" element={ <Registration />} />
                    <Route path="/user-profile" element={ <UserProfile />} />
                    <Route path="/wish-list" element={ <Cart />} />
                    <Route path="/product/:sub_id" element={ <Product />} />
                    <Route path="/about-us" element={ <AboutUs />} />
                    <Route path="/cloud-based-sales-management-tools" element={ <CBSMT />} />
                    <Route path="/remote-infrastructure-management" element={ <RIM />} />
                    <Route path="/cloud-virtualization" element={ <CloudVirtualization />} />
                    <Route path="/network-security" element={ <NetworkSecurity />} />
                    
                    <Route path="/backup-solution" element={ <BackupSolution />} />
                    <Route path="/software-solution" element={ <SoftwareSolution />} />
                    <Route path="/it-solutions" element={ <ItSolutions />} />
                    
                    <Route path="/contact-us" element={ <ContactUs />} />
                    <Route path="/category/:id" element={ <Category/>} />  
                    <Route path="/products-details/:product_id" element={ <ProductsDetails />} />
                    <Route path="/products-search/:part_number" element={ <ProductSearch />} />                    
                </Routes>
                {/* <Footer/> */}
            </Router>  
        </>
    );
}

export default App;
