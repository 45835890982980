import React, { useState, useEffect } from 'react'
import {} from "react-router-dom";
import $ from 'jquery';
import { Country, State, City } from 'country-state-city';
import { message } from 'antd';
const axios = require('axios');

const UserProfile = () => {
    var loginData = JSON.parse(localStorage.loginData);
    const allCountry = Country.getAllCountries();
    const [title, setTitle] = useState(loginData.title);
    const [fname, setFname] = useState(loginData.fname);
    const [lname, setLname] = useState(loginData.lname);
    const [email, setEmail] = useState(loginData.email);
    const [phone, setPhone] = useState(loginData.phone);
    const [companyName, setCompanyName] = useState(loginData.company_name);
    const [postalCode, setPostalCode] = useState(loginData.postal_code);
    const [address, setAddress] = useState(loginData.address);
    const [country, setCountry] = useState(loginData.country);
    const [CountryCode, setCountryCode] = useState('');
    const [StateList, setStateList] = useState({});
    const [CityList, setCityList] = useState({});
    const [state, setState] = useState(loginData.state);
    const [city, setCity] = useState(loginData.city);
    const selectedCountry = async(value) => {
        let countryName = value.split('-');
        setCountry(countryName[0]+'-'+countryName[1]);
        setCountryCode(countryName[0]);
        const result = State.getAllStates().filter(State =>{
           return State.countryCode ===  countryName[0];
        });
        result.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setStateList(result);
    }
    const selectedState = async(value) => {
        let stateName = value.split('-');
        setState(stateName[0]+'-'+stateName[1]);
        const countryCites = City.getAllCities().filter(City =>{
           return City.countryCode ===  CountryCode
        });
        
        const finalCity =  countryCites.filter(City =>{
           return City.stateCode ===  stateName[0]
        });
        //finalCity.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setCityList(finalCity);  
        //checkCity();                                                                                             
    }
    // const checkCity = () => {
    //     //console.log(CityList);
    // };
    const updateAccount = (e) => {
        e.preventDefault();
        var validation = true;
        $('#fnameError').text("");
        $('#lnameError').text("");
        $('#emailError').text("");
        $('#phoneError').text("");
        $('#companyError').text("");
        $('#postalError').text("");
        $('#addressError').text("");
        $('#countryError').text("");
        $('#stateError').text("");
        $('#cityError').text("");
        
        if (fname === '' || fname.match("^[a-zA-Z ]*$") == null) {
            $('#fnameError').text("Fname is not valid");
            validation = false;
        }
        if (lname === '' || lname.match("^[a-zA-Z ]*$") == null) {
            $('#lnameError').text("Lname is not valid");
            validation = false;
        }
        const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        if (!validEmail.test(email)) {
            $('#emailError').text("Email is not valid");
            validation = false;
        }
        const validPhone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (!validPhone.test(phone)) {
            $('#phoneError').text("Phone no is not valid");
            validation = false;
        }
        
        if ( companyName === undefined || companyName.trim() === '') {
            $('#companyError').text("Company name is not valid");
            validation = false;
        }
        const validPost = /^[0-9]{4,6}$/;
        if (!validPost.test(postalCode)) {
            $('#postalError').text("postal code is not valid");
            validation = false;
        }
        const validAdd = /^.{6,}$/;
        if (!validAdd.test(address)) {
            $('#addressError').text("Address is not valid");
            validation = false;
        }
        if (!country || country === 0) {
            $('#countryError').text("Please select country");
            validation = false;
        }
        if (!state || state === 0) {
            $('#stateError').text("Please select state");
            validation = false;
        }
        if (!city || city === 0) {
            $('#cityError').text("Please select city");
            validation = false;
        }

        if (validation === false) {
            return false;
        }
        

        const data = {
            'title': title,
            'fname': fname,
            'lname': lname,
            'email': email,
            'phone': phone,
            'company_name': companyName,
            'postal_code': postalCode,
            'address': address,
            'country': country,
            'state': state,
            'city': city,
        }
        try {
            $('.loaderClass').show();
            axios.post('https://api.spmsale.com/api/update-user', data)
                .then(function (response) {
                    if (response.data) {
                        const loginData = JSON.stringify(response.data);
                        localStorage.setItem('loginData', loginData);
                        message.success("User detail updated successfully.");                        
                    } else {
                        $('#emailError').text("Something went wrong, Please try after sometime");
                    }
                    $('.loaderClass').hide();
                })
                .catch(function (error) {
                    $('.loaderClass').hide();
                    console.log(error);
                });
        } catch (error) {
            $('.loaderClass').hide();
            console.log(error);
        }
    }
    useEffect(() => {
        selectedCountry(loginData.country);
     }, [loginData.country]);
    return (

        <section className="web_register">
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="register_account">
                            <h1>Update your own account</h1>
                            <div className="register_account_part">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_title">
                                            <h3><i className="fa fa-user" aria-hidden="true"></i> Your Personal Details</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                        <div className="ra_tab">
                                            <label className="select margin-bottom-15">Title :</label>
                                            <select id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} className="form-control">
                                                <option value="Mr">Mr.</option>
                                                <option value="Ms">Ms.</option>
                                                <option value="Mrs">Mrs.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="ra_tab">
                                            <label>First Name :</label>
                                            <input type="text" id="fname" name="fname" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First name" className="form-control" />
                                            <div id="fnameError" className="error" ></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="ra_tab">
                                            <label>Last Name :</label>
                                            <input type="text" id="lname" name="lname" value={lname} onChange={(e) => setLname(e.target.value)} placeholder="Last name" className="form-control" />
                                            <div id="lnameError" className="error" ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_tab">
                                            <label>Email Address :</label>
                                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" className="form-control" disabled/>
                                            <div id="emailError" className="error" ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_tab">
                                            <label>Phone Number :</label>
                                            <input type="phone" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number (e.g. +1 (925) 397-1610)" className="form-control" />
                                            <div id="phoneError" className="error" ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="register_account_part">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_title">
                                            <h3><i className="fa fa-building" aria-hidden="true"></i> Company Details</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="ra_tab">
                                            <label>Company Name :</label>
                                            <input type="text" id="company_name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} name="company_name" placeholder="Company Name" className="form-control" />
                                            <div id="companyError" className="error" ></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div className="ra_tab">
                                            <label>Postal Code :</label>
                                            <input type="text" id="postal_code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} name="postal_code" placeholder="Postal Code" className="form-control" />
                                            <div id="postalError" className="error" ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_tab">
                                            <label className="textarea">Address :</label>
                                            <textarea placeholder="Address" id="address" name="address"
                                                value={address}
                                                onChange={e => setAddress(e.target.value)} className="form-control"></textarea>
                                            <div id="addressError" className="error" ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="register_account_part">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div className="ra_tab">
                                            <label className="select margin-bottom-15">Country :</label>
                                            <select id="country" name="country" value={country}
                                                onChange={e => selectedCountry(e.target.value)} className="form-control">
                                                <option value="0">Select Country</option>
                                                {
                                                    allCountry.map((value, key) => {
                                                        return (
                                                            <option value={value.isoCode + '-' + value.name} key={key}>
                                                                {value.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            <div id="countryError" className="error" ></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div className="ra_tab">
                                            <label className="select margin-bottom-15">State :</label>
                                            <div id="txtHintState">
                                                <select className="form-control" id="state" value={state}
                                                    onChange={e => selectedState(e.target.value)} name="state" aria-hidden="true">
                                                    <option value="0">Select State</option>
                                                    
                                                    {
                                                        StateList.length > 0 ?
                                                        StateList.map((value, key) => {
                                                            return (
                                                                <option value={value.isoCode + '-' + value.name} key={key}>
                                                                    {value.name}
                                                                </option>
                                                            );
                                                        })
                                                        : ''
                                                        
                                                    }
                                                </select>
                                            </div>
                                            <div id="stateError" className="error" ></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div className="ra_tab">
                                            <label className="select margin-bottom-15">City :</label>
                                            <div id="txtHintCity">
                                                <select className="form-control" id="city" value={city}
                                                    onChange={e => setCity(e.target.value)} name="city" aria-hidden="true">
                                                    <option value="0">Select City</option>
                                                    {
                                                        CityList.length > 0 ?
                                                        CityList.map((value, key) => {
                                                            return (
                                                                <option value={value.name} key={key}>
                                                                    {value.name}
                                                                </option>
                                                            );
                                                        })
                                                        :  <option value={city} key={city}>
                                                                    {city}
                                                                </option>
                                                       
                                                        
                                                    }
                                                </select>
                                            </div>
                                            <div id="txtHintOther"></div>
                                            <div id="cityError" className="error"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="ra_button">
                                            <button type="button" name="btn_register" className="registartion_button btn-u btn-u-sea-shop btn-block margin-bottom-30" onClick={updateAccount}>Update Account</button><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        {/* <div className="contact_infos">
                            <h3>Sign up today and you'll be able to</h3>
                            <div className="contact_info">
                                <i className="fa fa-check"></i>
                                <span> Speed your way through the checkout</span>
                            </div>
                            <div className="contact_info">
                                <i className="fa fa-check"></i>
                                <span> Track your orders easily</span>
                            </div>
                            <div className="contact_info">
                                <i className="fa fa-check"></i>
                                <span> Keep a record of all your purchases</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            
        </section>

    )
}

export default UserProfile
