import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import ContactForm from "./ContactForm";
import $ from "jquery";
import { message } from "antd";
const axios = require("axios");
message.config({
  top: 200
});
const ProductsDetails = () => {
 // const [loginData, setLoginData] = useState(localStorage.loginData);
  const [ProductDetail, setProductDetail] = useState([]);
  const { product_id } = useParams();
  if (localStorage.loginData) {
    var loginData = JSON.parse(localStorage.loginData);
  } else {
    loginData = {
      fname: "",
      email: "",
      phone: "",
    };
  }

  const [FullName, setFullName] = useState(loginData.fname + " " + loginData.lname);
  const [Email, setEmail] = useState(loginData.email);
  const [PhoneNumber, setPhoneNumber] = useState(loginData.phone);
  const [Subject, setSubject] = useState("");
  const [Details, setDetails] = useState("");
  const [Quntity,setQuntity] = useState([0]);;
  const contactFormSubmit = (e) => {
    e.preventDefault();
    var validation = true;
    $("#nameError").text("");
    $("#emailError").text("");
    $("#phoneError").text("");
    $("#subjectError").text("");
    $("#detailsError").text("");
    $("#QuntityError").text("");
    
    if (FullName === "" || FullName.match("^[a-zA-Z ]*$") == null) {
      $("#nameError").text("FullName is not valid");
      validation = false;
    }
    const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!validEmail.test(Email)) {
      $("#emailError").text("Email is not valid");
      validation = false;
    }
    const validPhone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!validPhone.test(PhoneNumber)) {
      $("#phoneError").text("Phone no is not valid");
      validation = false;
    }
    if (Subject === "" || Subject.match("^[a-zA-Z ]*$") == null) {
      $("#subjectError").text("Subject is not valid");
      validation = false;
    }
    if (Details === "") {
      $("#detailsError").text("Details is not valid");
      validation = false;
    }
    
    if (Quntity <= 0) {
      $("#QuntityError").text("Please fill valid quntity");
      validation = false;
    }
   
    var requestQuote=  [];
    requestQuote.push(ProductDetail.part_number+ ' -> ' + Quntity);
    
    if (validation === false) {
      return false;
    }
    
    const data = {
      FullName: FullName,
      Email: Email,
      PhoneNumber: PhoneNumber,
      Subject: Subject,
      Details: Details,
      requestQuote: JSON.stringify(requestQuote),
    };

    try {
      $(".loaderClass").show();
      
      axios
        .post("https://api.spmsale.com/api/send-email", data)
        .then(function (response) {
          if (response.data.Success) {
           message.success("mail successfully sent, Please check your mail box");
            setTimeout(function(){
              $(".loaderClass").hide();
              window.location.reload(false);
            }, 3000);          
          }
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  
  const getProductDetail = (product_id) => {
    $(".loaderClass").show();
    try {
      axios
        .post("https://api.spmsale.com/api/product-detail", { id: product_id })
        .then(function (response) {
          if (response.data.status) {
            setProductDetail(response.data.data);
            $(".loaderClass").hide();
          }
        })
        .catch(function (error) {
          $(".loaderClass").hide();
          console.log(error);
        });
    } catch (error) {
      $(".loaderClass").hide();
      console.log(error);
    }
  };
  useEffect(() => {
    getProductDetail(product_id);
  }, [product_id]);
  return (
    <section className="web_content">
      <div className="content_section_scroll">
        <div className="container-fluid">
          <div className="row d-flex align-items-top">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="common_content">
                <h1>Quote Request For : {ProductDetail.part_number}</h1>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <span className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    {loginData ? (
                      <button type="button" className="btn btn-primary requestButton" data-toggle="modal" data-target="#exampleModal">
                        Request for Quote
                      </button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="product_content">
                  <div className="product_table">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <td>Specification</td>
                          <td>Value</td>
                          <td>Specification</td>
                          <td>Value</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Part Number</td>
                          <td>{ProductDetail.part_number}</td>
                          <td>Manufacturer</td>
                          <td>{ProductDetail.manufacturer}</td>
                        </tr>
                        <tr>
                          <td>NSN Part number</td>
                          <td>{ProductDetail.nsn_part_number}</td>
                          <td>Inventory Type</td>
                          <td>{ProductDetail.inventory_type}</td>
                        </tr>
                        <tr>
                          <td>Date Code</td>
                          <td>{ProductDetail.date_code}</td>
                          <td>Total Available</td>
                          <td>{ProductDetail.total_available}</td>
                        </tr>
                        <tr>
                          <td>Parts Description</td>
                          <td>{ProductDetail.parts_description}</td>
                          <td>Category</td>
                          <td>{ProductDetail.category}</td>
                        </tr>
                        <tr>
                          <td>Total Excess</td>
                          <td>{ProductDetail.total_excess}</td>
                          <td>Condition</td>
                          <td>{ProductDetail.condition}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Request for Quote
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="contact_form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="text" name="full_name" value={FullName} onChange={(e) => setFullName(e.target.value)} placeholder="Name" />
                    </div>
                    <div id="nameError" className="error"></div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="text" name="phone" onChange={(e) => setPhoneNumber(e.target.value)} value={PhoneNumber} placeholder="Phone Number" />
                    </div>
                    <div id="phoneError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={Email} placeholder="E-mail" />
                    </div>
                    <div id="emailError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <input type="text" name="subject" onChange={(e) => setSubject(e.target.value)} value={Subject} placeholder="Subject" />
                    </div>
                    <div id="subjectError" className="error"></div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="text" name="partno" value={ProductDetail.part_number} placeholder="partno" disabled />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cf_tab">
                      <input type="number" name="quntity" value={Quntity} onChange={(e) => setQuntity(e.target.value)} placeholder="Quntity" />
                    </div>
                    <div id="QuntityError" className="error"></div>
                  </div>
                </div>
                     
                
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <textarea placeholder="Details" onChange={(e) => setDetails(e.target.value)} value={Details}></textarea>
                    </div>
                    <div id="detailsError" className="error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cf_tab">
                      <button type="submit" className="btn btn_inquiry" onClick={contactFormSubmit}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsDetails;
