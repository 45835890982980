import React from 'react'

const BackupSolution = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <div className="common_content mt-5">
                                    <h1>What is Backup Solutions?</h1>
                                    <p>Enterprises often go to great lengths to make sure their data is effectively stored and managed. The process of data storage and management puts significant pressure on enterprise IT resources, as well as the IT teams’ ability to focus on other more critical parts of the business.</p>
                                    <p>SPM provides a highly scalable, dependable and comprehensive range of data storage and backup solutions, designed to offer the highest levels of protection to your critical business assets. Comprehensive suite of DR Services, backed by world-class infrastructure and end-to- end managed services, ensures that your mission critical systems continue to be available and responsive under the most adverse of circumstances. We help you build and manage a robust DR system that you can trust, ensuring that your customers and partners never feel a difference in your quality of service.</p>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <img className="BackupSolution" src="/images/backup.jpg" alt="BackupSolution" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BackupSolution
