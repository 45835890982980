import React from 'react'

const CloudVirtualization = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <div className="common_content mt-5">
                                    <h1>What is Cloud & Virtualization Management?</h1>
                                    <p>Digital disruption is impacting businesses in every industry and today’s technology-enabled customers are empowered and highly demanding. To meet the needs of these global digital customers, organizations must transform into digital businesses – they must become increasingly agile, flexible, responsive and efficient. But traditional IT systems too often make digital transformation difficult.</p>
                                    <p>We support and manage the world’s leading cloud platforms, saving you the pain and complexity of doing it yourself. With Inter Tech's private cloud managed IT services, you only pay for the services you need. It's a great way to reduce costs, gain efficiency and enhance the overall management, reliability and accessibility of your existing infrastructure solutions.</p>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <img className="CloudVirtualization" src="/images/cloud.jpg" alt="CloudVirtualization" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CloudVirtualization
