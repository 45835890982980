import React from 'react'

const RIM = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <div className="common_content mt-5">
                                    <h1>What is Remote infrastructure Management?</h1>
                                    <p>Global businesses need the freedom to modernize their IT and put their workloads where they’ll fit best. Our Professional Services solution engineers have expertise and experience in all the major platforms and technologies. As your trusted partner, we can handle your migration details for you and provide ongoing management and optimization, so you can focus on your core business.</p>
                                    <p>Re-engineering of your existing infrastructure with the help of our experts to improve the performance with the latest technology and solutions based on client requirements. SPM has engineers and architects that can help accelerate your growth and innovation at any point in your business journey.</p>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-2 col-sm-6">
                                <img className="RIM" src="/images/remote.jpg" alt="Remote infrastructure Management" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RIM
