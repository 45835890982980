import React from 'react'

const CBSMT = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-2 col-sm-12">
                                <div className="common_content">
                                    {/* <h1>Cloud based sales management tools</h1> */}
                                    <img className="comingSoon" src="/images/coming-soon.jpg" alt="coming-soon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CBSMT
