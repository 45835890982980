
import React, { useState } from 'react'
import $ from 'jquery';
import { message } from 'antd';
message.config({
    top: 200
  });
const axios = require('axios');

const ForgotPass = () => {
    const [Email, setEmail] = useState('');
    const FormSubmit = (e) => {
        e.preventDefault();
        var validation = true;
        $('#mailError').text("");
        const validEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        if (!validEmail.test(Email)) {
            $('#mailError').text("Right need to replace with valid.");
            validation = false;
        }
        if (validation === false) {
            return false;
        }
        const data = {
            'Email': Email
        }
        try {
            $('.loaderClass').show();
            axios.post('https://api.spmsale.com/api/forgot-pass', data)
                .then(function (response) {
                    if (response.data.Success) {
                        $('.loaderClass').hide();
                        message.success("Password reset mail has been successfully sent, Please check your mail box.")
                        window.$('#ForgotPassModel').modal('hide');
                    } else {
                        $('.loaderClass').hide();
                        message.error(response.data.error);                        
                    }
                })
                .catch(function (error) {
                    $('.loaderClass').hide();
                    console.log(error);
                });
        } catch (error) {
            $('.loaderClass').hide();
            console.log(error);
        }
    };
    return (

        <div className="contact_form">
            <div className="row">
                <div className="col-md-12">
                    <div className="cf_tab">
                        <input type="text" name="email" onChange={e => setEmail(e.target.value)} required="" id="" value={Email} placeholder="Enter your E-mail" />
                    </div>
                    <div id="mailError" className="error" ></div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="cf_tab">
                        <button type="submit" className="btn btn_inquiry" onClick={FormSubmit}>Send</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ForgotPass
