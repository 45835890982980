import React from 'react'

const FixRight = () => {
    return (

        <div className="spm_fix_right">
            <div className="spm_contact_info">
                <ul>
                    <li className="spm_ci_one"><a href="#"><i className="fa fa-map-marker"></i> <span className="spm_text">3155 Half
                        Dome Drive Pleasanton, CA 94566</span></a></li>
                    <li className="spm_ci_two"><a href="tel:+19253971610"><i className="fa fa-phone"></i> <span className="spm_text">+1
                        (925) 397-1610</span></a></li>
                    <li className="spm_ci_three"><a href="mailto:sales@spmsale.com"><i className="fa fa-envelope"></i> <span
                        className="spm_text">sales@spmsale.com</span></a></li>
                </ul>
            </div>
        </div>

    )
}

export default FixRight
