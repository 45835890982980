import React from 'react'
import { NavLink } from "react-router-dom";
const ItSolutions = () => {
    return (
        <section className="web_content">
            <div className="common_bg">
                <div className="content_section">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-top">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="common_content">
                                    <h1>It Solutions</h1>
                                    <div className="product_content">
                                        <div className="product_table">
                                            <table className="table table-bordered" id='productTable'>
                                                <thead>
                                                    <tr>
                                                        <td className='width10'>No</td>
                                                        <td className='width42'>Page Name</td>
                                                        <td className='width20'>Action</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Cloud & Virtualization</td>
                                                        <td><NavLink className="btn_table" to={"/cloud-virtualization"}>Go-to</NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Network & Security</td>
                                                        <td><NavLink className="btn_table" to={"/network-security"}>Go-to</NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Remote Infrastructure Management</td>
                                                        <td><NavLink className="btn_table" to={"/remote-infrastructure-management"}>Go-to</NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Backup Solution</td>
                                                        <td><NavLink className="btn_table" to={"/backup-solution"}>Go-to</NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Cloud Based Sales Management Tools</td>
                                                        <td><NavLink className="btn_table" to={"/cloud-based-sales-management-tools"}>Go-to</NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Software Solution</td>
                                                        <td><NavLink className="btn_table" to={"/software-solution"}>Go-to</NavLink></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ItSolutions
